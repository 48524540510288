@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-emint-gray-600;
  }
}

/* Remove arrows from number type inputs */
@layer utilities {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

input[type='text']:focus ~ .input-text {
  @apply text-blue-500 transform scale-75 -translate-x-4 -translate-y-9;
}

input[type='text']:not(:placeholder-shown) ~ .input-text {
  @apply transform scale-75 -translate-x-4 -translate-y-9;
}

input[type='email']:focus ~ .input-text,
input[type='email']:not(:placeholder-shown) ~ .input-text {
  @apply text-blue-500 transform scale-75 -translate-x-4 -translate-y-9;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: left;
  font-size: 18px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

div.swiper-scrollbar-horizontal {
  background: grey;
}

.no-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.emint-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.emint-scrollbar::-webkit-scrollbar-track {
  background: #202226;
}

.emint-scrollbar::-webkit-scrollbar-thumb {
  background-color: #7974fb;
  border-radius: 20px;
  border: 2px solid #202226;
}

.emint-scrollbar {
  scrollbar-width: auto;
  scrollbar-color: #7974fb #202226;
}

.color-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.color-input::-webkit-color-swatch {
  border: none;
}

.color-input::-moz-color-swatch {
  border: none;
}

/* Native Accordion Styles  */

.sum-details .sum-open {
  display: none;
}

.sum-details .sum-closed {
  display: block;
}

.sum-details[open] .sum-open {
  display: block;
}

.sum-details[open] .sum-closed {
  display: none;
}
